import { Modal, Switch, Typography } from 'antd';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

export interface PlusServiceItem {
  value: string;
  label: string;
  checked: boolean;
  renderBy?: string;
}

interface PlusServiceListModalProps {
  data: PlusServiceItem[];
  callback: (params: PlusServiceItem[]) => Promise<unknown>;
  onCancel: () => void;
}

@observer
export class PlusServiceListModal extends React.Component<PlusServiceListModalProps> {
  constructor(props: PlusServiceListModalProps) {
    super(props);
    this.plusServiceData = toJS(props.data);
  }

  @observable public loading = false;

  @observable public plusServiceData: PlusServiceItem[] = [];

  @action public onOk = () => {
    this.loading = true;
    this.props.callback(this.plusServiceData)
      .catch(() => this.loading = false);
  };

  render() {
    const {
      props: { onCancel },
      loading,
      onOk,
      plusServiceData,
    } = this;
    return (
      <Modal
        centered
        maskClosable={false}
        okButtonProps={{ loading }}
        onCancel={onCancel}
        onOk={onOk}
        title="增值服务"
        visible
        width={500}
      >
        <div className={styles.plusServiceListModalContainer}>
          {
            plusServiceData.map((item, _, array) => {
              if (item.renderBy && !array.find((val) => val.value === item.renderBy && val.checked)) {
                return null;
              }

              return (
                <section key={item.value}>
                  <Typography.Title level={3}>
                    {item.label}
                  </Typography.Title>
                  <Switch
                    checked={item.checked}
                    checkedChildren="开"
                    onClick={() => item.checked = !item.checked}
                    unCheckedChildren="关"
                  />
                </section>
              );
            })
          }
        </div>

      </Modal>
    );
  }
}
