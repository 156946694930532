import { message, Modal } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { destroyAllModal, renderModal } from 'egenie-common';
import type { BaseData, PaginationData } from 'egenie-utils';
import { NormalProgramme, request } from 'egenie-utils';
import { action, autorun, computed, observable } from 'mobx';
import React from 'react';
import { ENUM_OWNER_MANAGE_PLUS_SERVICE_TYPE } from '../../utils';
import { PlusServiceListModal } from './plusServiceListModal';
import type { EnableOwnerWarehouseBindInfo, OwnerItem } from './types';
import { CourierSettingModal } from './courierSettingModal/courierSettingModal';

export class Store {
  constructor() {
    this.normalProgramme = new NormalProgramme({
      count: 6,
      filterItems: [
        {
          type: 'input',
          field: 'ownerUniqueName',
          label: '货主名',
        },
        {
          type: 'input',
          field: 'ownerCellphone',
          label: '手机号',
        },
        {
          type: 'dateRange',
          field: 'creationTime',
          label: '开通时间',
          format: 'YYYY-MM-DD',
          formatParams: 'YYYY-MM-DD',
          startParamsField: 'enableBegin',
          endParamsField: 'enableEnd',
        },
        {
          type: 'select',
          field: 'insteadOwner',
          label: '是否代发客户',
          data: [
            {
              value: '1',
              label: '是',
            },
            {
              value: '0',
              label: '否',
            },
          ],
        },
        {
          type: 'select',
          field: 'forceStopDeliver',
          label: '强制停发',
          data: [
            {
              value: '1',
              label: '是',
            },
            {
              value: '0',
              label: '否',
            },
          ],
        },
      ],

      handleSearch: async() => {
        this.page = 1;
        await this.queryList();
      },
    });
    this.getOwnerRealInfo();
    autorun(() => {
      this.queryList({
        page: this.page,
        enable: this.enable,
      });
    });
  }

  public normalProgramme: NormalProgramme;

  @observable public data: OwnerItem[] = [];

  @observable public enableOwnerWarehouseBindInfoData: EnableOwnerWarehouseBindInfo[] = [];

  @observable public page = 1;

  @observable public pageSize = 11;

  @observable public total = 0;

  @observable public sourceFrom = 0;

  @observable public isModalVisible = false;

  @observable public confirmLoading = false;

  @observable public enable = 2; // 0 禁用  1 启用  2 全部

  @observable public addFromRef = React.createRef<FormInstance>();

  @action
  public handleOk = async(e): Promise<void> => {
    try {
      this.confirmLoading = true;

      const params = await this.addFromRef.current.validateFields();
      this.enableOwnerWarehouseBindInfoData.forEach((item) => {
        if (item.ownerId === params.ownerName) {
          params.ownerName = item.ownerName;
          params.ownerCellphone = item.ownerCellphone;
          params.ownerId = item.ownerId;
        }
      });

      const req = await request<BaseData<{ status: string; data: string; }>>({
        method: 'post',
        url: '/api/cloud/baseinfo/rest/owner/bindOwnerWarehouse',
        data: { ...params },
      });
      this.onAddReset();
      this.queryList();
      message.success(req.data || '成功');
    } catch (e) {
      console.error(e);
    } finally {
      this.confirmLoading = false;
    }
  };

  @action public handleOwnerTypeChange = (item: OwnerItem, ownerTypeListIndex: number, checked: boolean): void => {
    const selectOwnerType = item.ownerTypeList.map((val) => val.enable);
    selectOwnerType[ownerTypeListIndex] = checked;
    if (selectOwnerType.every((val) => !val)) {
      const error = '请至少选择一种货主类型';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    request({
      url: '/api/cloud/baseinfo/rest/owner/enableSupportType',
      method: 'post',
      data: {
        cloudOwnerWarehouseBindId: item.id,
        enable: checked,
        supportOrderType: item.ownerTypeList[ownerTypeListIndex].supportOrderType,
      },
    })
      .then(() => {
        message.success('设置货主类型成功');
        item.ownerTypeList[ownerTypeListIndex].enable = checked;
      });
  };

  @action
  public queryList = async(data = {}): Promise<void> => {
    const params = {
      enable: this.enable,
      page: this.page,
      pageSize: this.pageSize,
      enableBegin: '',
      enableEnd: '',
      ...this.normalProgramme.filterItems.params,
      ...data,
    };

    const req = await request<PaginationData<OwnerItem>>({
      method: 'post',
      url: '/api/cloud/baseinfo/rest/owner/queryOwnerReal',
      data: params,
    });

    this.total = req.data.totalCount || 0;

    this.data = (req.data?.list || []).map((item) => {
      const ownerTypeList = Array.isArray(item.ownerTypeList) ? item.ownerTypeList : [];
      return {
        ...item,
        _checked: false,
        addedValueService: (item.addedValueService || []).map((item) => `${item}`),
        ownerTypeList,
      };
    });
  };

  @computed
  public get checkedItems(): OwnerItem[] {
    return this.data.filter((item) => item._checked);
  }

  @action public handlePlusServiceModal = (ownerIds: Array<OwnerItem['ownerId']>, openedService: OwnerItem['addedValueService']) => {
    const data = Object.values(ENUM_OWNER_MANAGE_PLUS_SERVICE_TYPE)
      .map((item) => ({
        ...item,
        checked: openedService.includes(item.value),
      }));

    renderModal(
      <PlusServiceListModal
        callback={(async(params) => {
          await request({
            url: '/api/cloud/baseinfo/rest/value/added/edit',
            method: 'post',
            data: {
              ownerIds,
              addedValueService: params.filter((item) => item.checked)
                .map((item) => item.value),
            },
          });

          message.success('开通成功');
          this.queryList();
          destroyAllModal();
        })}
        data={data}
        onCancel={destroyAllModal}
      />
    );
  };

  @observable public activeItem: OwnerItem = null;

  @observable public showEditPlusServiceModal = false;

  @action public handleOpenEditPlusServiceModal = (item: OwnerItem) => {
    this.activeItem = item;
    this.showEditPlusServiceModal = true;
  };

  @action public handleCloseEditPlusServiceModal = (): void => {
    this.activeItem = null;
    this.showEditPlusServiceModal = false;
  };

  @action
  public getOwnerRealInfo = async(params = {}): Promise<void> => {
    const req = await request<PaginationData<EnableOwnerWarehouseBindInfo>>({
      method: 'post',
      url: '/api/cloud/baseinfo/rest/owner/queryThirdOwnerRealInfo',
      data: {
        sourceFrom: this.sourceFrom,
        page: 1,
        pageSize: 1000,
        ...params,
      },
    });
    this.enableOwnerWarehouseBindInfoData = req.data.list || [];
  };

  @action
  public onShow = (): void => {
    this.isModalVisible = true;
  };

  @action
  public onAddReset = (): void => {
    this.addFromRef.current!.resetFields();
    this.sourceFrom = 0;
    this.getOwnerRealInfo();
    this.isModalVisible = false;
  };

  @action
  public onRadioChange = (e): void => {
    this.enable = e.target.value;
    this.page = 1;
  };

  @action
  public onPaginationChange = (page: number): void => {
    this.page = page;
  };

  @action
  public handleDeliverStatusChange = (item: OwnerItem): void => {
    Modal.confirm({
      title: item.deliverStatus ? '确定停止发货吗?' : '确定恢复发货吗?',
      centered: true,
      onOk: async() => {
        await request({
          method: 'post',
          url: '/api/cloud/baseinfo/rest/owner/enableOwnerDeliver',
          data: {
            cloudOwnerWarehouseBindId: item.id,
            enable: !item.deliverStatus,
          },
        });
        await this.queryList();
        message.success('操作成功');
      },
    });
  };

  @action
  public handleForceStopDeliverChange = (item: OwnerItem): void => {
    Modal.confirm({
      title: item.forceStopDeliver ? '确定关闭强制停发吗?' : '确定开启强制停发吗?',
      centered: true,
      onOk: async() => {
        await request({
          method: 'post',
          url: '/api/cloud/baseinfo/rest/owner/enableForceStopDeliver',
          data: {
            cloudOwnerWarehouseBindId: item.id,
            enable: !item.forceStopDeliver,
          },
        });
        await this.queryList();
        message.success('操作成功');
      },
    });
  };

  @action
  public onSwitchClick = (id: string, enable: boolean): void => {
    if (enable) {
      Modal.confirm({
        title: '确认禁用该用户吗？',
        content: '禁用后，将不再为此用户推送网仓 信息。',
        onOk: () => this.openOrDisable(id, enable),
      });
    } else {
      this.openOrDisable(id, enable);
    }
  };

  public openOrDisable = async(id: string, enable: boolean): Promise<void> => {
    await request({
      method: 'post',
      url: '/api/cloud/baseinfo/rest/owner/enableOwnerWarehouseBindInfo',
      data: {
        cloudOwnerWarehouseBindId: id,
        enable: !enable,
      },
    });
    await this.queryList();
    message.success(!enable ? '已成功为该货主启用网仓功能' : '已成功为该货主禁用云仓功能');
  };

  public handleSearch = async(key, value) => {
    if (value) {
      await this.getOwnerRealInfo({ [key]: value });
    } else {
      this.enableOwnerWarehouseBindInfoData = [];
    }
  };

  public onSelect = (key, value) => {
    this.addFromRef.current.setFieldsValue({ [key]: value });
  };

  @action
  public onSourceFromChange = (e) => {
    this.sourceFrom = e.target.value;
    this.addFromRef.current.setFieldsValue({
      ownerName: undefined,
      ownerCellphone: undefined,
    });
    this.getOwnerRealInfo();
  };

  @action public handleOpenCourierSettingModal = (item: OwnerItem) => {
    renderModal(
      <CourierSettingModal
        callback={() => {
          destroyAllModal();
          this.queryList();
        }}
        mainItem={item}
        onClose={destroyAllModal}
      />
    );
  };
}

