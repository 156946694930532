import { Button, Checkbox, Col, Row, Space, Switch, Tag, Tooltip, Typography } from 'antd';
import classnames from 'classnames';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { ENUM_PLUS_SERVICE_TYPE, LogTooltip } from '../../utils';
import css from './shipperCard.less';
import type { Store } from './store';
import type { OwnerItem } from './types';

@inject('store')
@observer
class Content extends React.Component<{ store?: Store; index: number; item: OwnerItem; }> {
  render() {
    const {
      index,
      item,
      store: {
        handleOpenEditPlusServiceModal,
        handlePlusServiceModal,
        handleDeliverStatusChange,
        handleOpenCourierSettingModal,
        onSwitchClick,
        handleOwnerTypeChange,
        handleForceStopDeliverChange,
      },
    } = this.props;
    return (

      <div className={classnames(css.open)}>
        <div className={css.cardHeader}>
          <span className={classnames({
            [css.index]: true,
            [css.disable]: !item.enable,
          })}
          >
            <span>
              {(index + 1) < 10 ? `0${index + 1}` : index + 1}
            </span>
          </span>
        </div>
        <div className={css.cardBody}>
          <div className={css.ownerName}>
            <span>
              <Checkbox
                checked={item._checked}
                onChange={(event) => item._checked = event.target.checked}
              />
                &nbsp;
              {item.ownerUniqueName}
              (
              {item.ownerId}
              )
            </span>
            <span>
              {item.nickName}
            </span>
          </div>
          <div className={css.userInfoContainer}>
            <section>
              <div className={css.userInfo}>
                <i className={classnames('icon-user', css.iconColor)}/>
                  &nbsp;
                {item.ownerName}
              </div>
              <div className={css.userInfo}>
                <i className={classnames('icon-phone', css.iconColor)}/>
                  &nbsp;
                {item.ownerCellphone}
              </div>
            </section>
            <section>
              <Switch
                checked={item.enable}
                checkedChildren="启用"
                className={css.switch}
                onClick={() => onSwitchClick(item.id, item.enable)}
                unCheckedChildren="禁用"
              />
            </section>
          </div>
          <div className={css.cardFooter}>
            <span>
              操作员:
              &nbsp;
              {item.creatorName}
            </span>
            <span>
              {item.enableTime}
            </span>
          </div>
          <div className={css.plusService}>
            <section>
              <Typography.Text style={{ flexShrink: 0 }}>
                增值服务:&nbsp;&nbsp;
              </Typography.Text>
              {
                item.addedValueService.length === 0 ? (
                  <Button
                    onClick={() => handlePlusServiceModal([item.ownerId], [])}
                    size="small"
                  >
                    开通
                  </Button>
                ) : (
                  item.addedValueService.map((val) => (
                    <Tag
                      color="blue"
                      key={val}
                    >
                      {Object.values(ENUM_PLUS_SERVICE_TYPE).find((labelData) => _.toString(labelData.value) === _.toString(val))?.label}
                    </Tag>
                  ))
                )
              }
            </section>
            <section>
              {
                item.addedValueService.length > 0 ? (
                  <Space>
                    <Tooltip title="增值服务">
                      <i
                        className={classnames('icon-function_setup', css.icon)}
                        onClick={() => handlePlusServiceModal([item.ownerId], item.addedValueService || [])}
                      />
                    </Tooltip>
                    <Tooltip title="编辑增值服务:店铺/商品/供应商">
                      <i
                        className={classnames('icon-maintain', css.icon)}
                        onClick={() => handleOpenEditPlusServiceModal(item)}
                      />
                    </Tooltip>
                  </Space>
                ) : null
              }

            </section>
          </div>
          <Row align="middle">
            <Typography.Text style={{ flexShrink: 0 }}>
              货主类型:&nbsp;&nbsp;
            </Typography.Text>
            {
              item.ownerTypeList.map((val, valIndex) => (
                <Checkbox
                  checked={val.enable}
                  key={val.supportOrderType}
                  onChange={(event) => handleOwnerTypeChange(item, valIndex, event.target.checked)}
                >
                  {val.name}
                </Checkbox>
              ))
            }
          </Row>
          <div className={css.courierContainer}>
            <Typography.Text style={{ flexShrink: 0 }}>
              指定快递:&nbsp;&nbsp;
            </Typography.Text>
            {
              _.isEmpty(item.courierNames) ? (
                <a onClick={() => handleOpenCourierSettingModal(item)}>
                  设置
                </a>
              ) : (
                <section>
                  <Typography.Text
                    ellipsis
                    title={item.courierNames}
                  >
                    {item.courierNames}
                  </Typography.Text>
                  <a onClick={() => handleOpenCourierSettingModal(item)}>
                    详情
                  </a>
                </section>
              )
            }
          </div>
          <div className={css.deliverStatus}>
            发货状态:&nbsp;&nbsp;
            <Switch
              checked={item.deliverStatus}
              onClick={() => handleDeliverStatusChange(item)}
            />
              &nbsp;&nbsp;
            {
              item.deliverStatus ? (
                <Tag color="#0BC4A7">
                  发货中
                </Tag>
              ) : (
                <Tag color="#FF9948">
                  暂停发货
                </Tag>
              )
            }
            强制停发:&nbsp;&nbsp;
            <Switch
              checked={item.forceStopDeliver}
              onClick={() => handleForceStopDeliverChange(item)}
            />
            <LogTooltip
              children={(
                <a>
                  查看日志
                </a>
              )}
              entityId={item.ownerId}
              extraParams={{ tenantId: window?.top?.user?.tenantId }}
              height={230}
              logBizModuleCode={130000}
              popoverProps={{
                title: '日志',
                trigger: 'click',
              }}
              width={800}
            />
          </div>
        </div>
        <div className={classnames({
          [css.enable]: true,
          [css.close]: !item.enable,
        })}
        />
      </div>
    );
  }
}

@inject('store')
@observer
export class ShipperCard extends React.Component<{ store?: Store; }> {
  render() {
    const {
      data,
      onShow,
      onSwitchClick,
    } = this.props.store;
    return (
      <div className={css.page}>
        <Row
          gutter={[
            8,
            8,
          ]}
        >
          <Col
            className={classnames(css.card)}
            onClick={onShow}
            span={6}
            xxl={6}
          >
            <div className={css.add}>
              + 新增网仓货主
            </div>
          </Col>
          {
            data && data.map((item, index) => {
              return (
                <Col
                  className={classnames({ [css.card]: true })}
                  key={item.id}
                  span={6}
                  xxl={6}
                >
                  <Content
                    index={index}
                    item={item}
                    {...item}
                  />
                </Col>
              );
            })
          }
        </Row>
      </div>
    );
  }
}
