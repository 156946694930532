import { Checkbox, message, Modal } from 'antd';
import { destroyAllModal, renderModal, request } from 'egenie-common';
import { action, observable } from 'mobx';
import React from 'react';
import { ENUM_PLUS_SERVICE_TYPE, ENUM_QUALITY_TESTING_PLUS_SERVICE_NODE, getVendorByOwner } from '../../../utils';
import type { PlusServiceItem } from '../plusServiceListModal';
import { PlusServiceListModal } from '../plusServiceListModal';
import type { OwnerItem } from '../types';
import { ShopStore } from './shopStore';
import { SkuStore } from './skuStore';
import { SupplierStore } from './supplierStore';

export const ENUM_BUSINESS_TYPE = {
  shop: {
    value: '1',
    label: '按店铺',
  },
  sku: {
    value: '3',
    label: '按SKU',
  },
  supplier: {
    value: '2',
    label: '按供应商',
  },
};

export class Store {
  constructor(activeItem: Store['activeItem']) {
    this.activeItem = activeItem;

    this.shopStore = new ShopStore(this);
    this.skuStore = new SkuStore(this);
    this.supplierStore = new SupplierStore(this);
    this.state = this.shopStore;
    this.extraFilterItemsStore = new BusinessTypeStore(this);

    [
      this.shopStore,
      this.skuStore,
      this.supplierStore,
    ].forEach((item) => {
      item.gridModel.getFilterParams = () => item.normalProgramme.filterItems.params;
      item.normalProgramme.filterItems.connect(this.extraFilterItemsStore);
    });

    this.state.gridModel.onQuery();

    getVendorByOwner(this.activeItem.ownerId)
      .then((info) => {
        this.skuStore.normalProgramme.filterItems.addDict({ vendorIdList: info });
        this.supplierStore.normalProgramme.filterItems.addDict({ vendorIdList: info });
      });
  }

  public activeItem: OwnerItem;

  public shopStore: ShopStore;

  public skuStore: SkuStore;

  public supplierStore: SupplierStore;

  @observable.ref public state: ShopStore | SupplierStore | SkuStore;

  public extraFilterItemsStore: BusinessTypeStore;

  @action public handleInlinePlusServiceModal = (ids: Array<string | number>, businessType: string, plusServiceData: PlusServiceItem[]) => {
    renderModal(
      <PlusServiceListModal
        callback={(async(params) => {
          const qualityRestingDetail: string[] = Object.values(ENUM_QUALITY_TESTING_PLUS_SERVICE_NODE).map((val) => val.value);
          const addedValueServiceData = params.filter((item) => item.checked)
            .filter((item) => !qualityRestingDetail.includes(item.value))
            .map((item) => item.value);
          const qualityTestingDetailData = params.filter((item) => item.checked)
            .filter((item) => qualityRestingDetail.includes(item.value))
            .map((item) => item.value)
            .join(',');
          await request({
            url: '/api/cloud/baseinfo/rest/value/added/detail/edit',
            method: 'post',
            data: {
              ids,
              businessType,
              ownerId: this.activeItem.ownerId,
              addedValueService: addedValueServiceData,
              qualityTestingDetail: qualityTestingDetailData,
            },
          });

          message.success('开通成功');
          this.state.gridModel.onRefresh();
          destroyAllModal();
        })}
        data={plusServiceData}
        onCancel={destroyAllModal}
      />
    );
  };

  @action public handleBatchPlusServiceModal = (ids: Array<string | number>, businessType: string, openedService: OwnerItem['addedValueService']) => {
    const isTestingQuality = openedService.includes(ENUM_PLUS_SERVICE_TYPE.qualityTesting.value);
    let qualityTestingDetail = isTestingQuality ? [ENUM_QUALITY_TESTING_PLUS_SERVICE_NODE.afterSale.value] : [];
    Modal.confirm({
      title: '请选择开通还是关闭',
      content: isTestingQuality ? (
        <>
          <h3>
            请至少选择一个质检项
          </h3>
          <Checkbox.Group
            defaultValue={qualityTestingDetail}
            onChange={(value: string[]) => qualityTestingDetail = value}
            options={Object.values(ENUM_QUALITY_TESTING_PLUS_SERVICE_NODE)}
          />
        </>
      ) : null,
      closable: true,
      mask: false,
      okText: '开通',
      cancelText: '关闭',
      centered: true,
      onOk: async() => {
        if (isTestingQuality && qualityTestingDetail.length === 0) {
          const error = '请至少选择一个质检项';
          message.error({
            key: error,
            content: error,
          });
          throw new Error(error);
        }

        await request({
          url: '/api/cloud/baseinfo/rest/value/added/detail/edit/batch',
          method: 'post',
          data: {
            addedValueService: openedService,
            qualityTestingDetail: qualityTestingDetail.join(','),
            enable: true,
            ids,
            businessType,
            ownerId: this.activeItem.ownerId,
          },
        });
        message.success('开通成功');
        this.state.gridModel.onRefresh();
        destroyAllModal();
      },

      onCancel: async(event) => {
        // 点击modal右边上角x关闭
        if (event?.triggerCancel) {
          return;
        }

        // 按钮关闭
        await request({
          url: '/api/cloud/baseinfo/rest/value/added/detail/edit/batch',
          method: 'post',
          data: {
            addedValueService: openedService,
            enable: false,
            ids,
            businessType,
            ownerId: this.activeItem.ownerId,
          },
        });
        message.success('关闭成功');
        this.state.gridModel.onRefresh();
        destroyAllModal();
      },
    });
  };
}

class BusinessTypeStore {
  constructor(parent: Store) {
    this.parent = parent;
  }

  public parent: Store;

  @observable public businessType = ENUM_BUSINESS_TYPE.shop.value;

  @action public handleBusinessTypeChange = (businessType: string) => {
    this.businessType = businessType;

    if (businessType === ENUM_BUSINESS_TYPE.shop.value) {
      this.parent.state = this.parent.shopStore;
    } else if (businessType === ENUM_BUSINESS_TYPE.sku.value) {
      this.parent.state = this.parent.skuStore;
    } else {
      this.parent.state = this.parent.supplierStore;
    }

    this.parent.state.gridModel.onQuery();
  };

  public toParams() {
    return { ownerId: `${this.parent.activeItem.ownerId}` };
  }
}
