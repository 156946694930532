import { Button, Drawer, InputNumber, message, Row, Select, Space, Table } from 'antd';
import type { BaseData, ValueAndLabelData } from 'egenie-utils';
import { formatNumber, request, egeniePcTheme } from 'egenie-common';
import React from 'react';
import type { OwnerItem } from '../types';
import { useLocalStore, observer, Observer } from 'mobx-react';
import _ from 'lodash';
import { getCourierEnabled } from '../../../utils';
import { nanoid } from 'nanoid';
import { toJS } from 'mobx';
import styles from './courierSettingModal.less';

interface CourierSettingModalProps {
  mainItem?: OwnerItem;
  onClose?: (...arg: any[]) => any;
  callback?: (...arg: any[]) => any;
}

interface DetailItem {
  cloudOwnerCourierBindId?: string;
  ownerId?: string;
  courierId?: string;
  level?: number;
  _id?: string;
}

interface Store {
  loading: boolean;
  data: DetailItem[];
  courierData: ValueAndLabelData;
}

const min = 1;
const max = 99;

function formatValue(num: number | string): number {
  const tmp = formatNumber(num);
  if (tmp >= min && tmp <= max) {
    return tmp;
  } else {
    return min;
  }
}

export const CourierSettingModal: React.FC<CourierSettingModalProps> = observer(({
  mainItem,
  callback,
  onClose,
}) => {
  const store = useLocalStore<Store>(() => ({
    loading: false,
    data: [],
    courierData: [],
  }));

  const columns = [
    {
      title: '指定快递',
      dataIndex: 'courierId',
      width: 250,
      render: (__, ___, dataIndex) => (
        <Observer>
          {
            () => (
              <Select
                allowClear
                onChange={(courierId) => store.data[dataIndex].courierId = courierId}
                optionFilterProp="label"
                options={store.courierData}
                placeholder="请选择快递"
                style={{ width: '100%' }}
                value={_.toString(store.data[dataIndex].courierId) || undefined}
              />
            )
          }
        </Observer>

      ),
    },
    {
      title: '优先级',
      dataIndex: 'level',
      width: 200,
      render: (__, ___, dataIndex) => (
        <InputNumber
          max={max}
          min={min}
          onChange={(level) => store.data[dataIndex].level = formatValue(level)}
          placeholder="请输入优先级"
          style={{ width: '100%' }}
          value={store.data[dataIndex].level}
        />
      ),
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 100,
      render: (__, ___, dataIndex: number) => (
        <Button onClick={() => store.data.splice(dataIndex, 1)}>
          删除
        </Button>
      ),
    },
  ];

  React.useEffect(() => {
    request<BaseData<DetailItem[]>>({ url: `/api/cloud/baseinfo/rest/owner/courier/query/${mainItem.ownerId}` })
      .then((info) => store.data = (info.data || []).map((item) => ({
        ...item,
        level: formatValue(item.level),
        _id: nanoid(),
      })));

    getCourierEnabled()
      .then((courierData) => store.courierData = courierData);
  }, []);

  function handleSave() {
    if (_.uniqBy(store.data, 'level').length !== store.data.length) {
      const error = '请设置优先级,优先级不能重复';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    store.loading = true;
    request({
      method: 'post',
      url: '/api/cloud/baseinfo/rest/owner/courier/add/or/edit',
      data: {
        list: store.data.map((item) => ({
          cloudOwnerCourierBindId: item.cloudOwnerCourierBindId,
          courierId: item.courierId,
          level: item.level,
        })),
        ownerId: mainItem.ownerId,
      },
    })
      .then(() => {
        if (typeof callback === 'function') {
          callback();
        }
      })
      .catch(() => store.loading = false);
  }

  function handleAddItem() {
    if (store.data.length <= (max - min + 1)) {
      store.data.push({
        _id: nanoid(),
        ownerId: mainItem.ownerId,
      });
    }
  }

  return (
    <Drawer
      footer={(
        <Row justify="end">
          <Space size="small">
            <Button onClick={onClose}>
              取消
            </Button>
            <Button
              loading={store.loading}
              onClick={handleSave}
              type="primary"
            >
              确定
            </Button>
          </Space>
        </Row>
      )}
      maskClosable={false}
      onClose={onClose}
      title="设置指定快递"
      visible
      width={600}
    >
      <div className={styles.description}>
        <i className="icon-gth"/>
        优先级设置1-99;1为高优先级,不可重复
      </div>
      <Table
        bordered
        columns={columns}
        dataSource={toJS(store.data)}
        locale={{ emptyText: '没有数据' }}
        pagination={false}
        rowKey="_id"
        scroll={{ y: 540 }}
        size="small"
      />
      <Button
        block
        onClick={handleAddItem}
        style={{ marginTop: egeniePcTheme.spacing['egenie-spacing-xs'] }}
        type="dashed"
      >
        +&nbsp;添加指定快递
      </Button>
    </Drawer>
  );
});

