import { Button, Card, Col, Form, Input, Modal, Pagination, Radio, Row, Select, Space } from 'antd';
import { NormalProgrammeComponent } from 'egenie-utils';
import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import css from './index.less';
import { PlusServiceEditModal } from './plusServiceEditModal/plusServiceEditModal';
import { ShipperCard } from './shipperCard';
import { Store } from './store';

@inject('store')
@observer
class AddModal extends React.Component<{ store?: Store; }> {
  render() {
    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    const {
      confirmLoading,
      handleOk,
      isModalVisible,
      addFromRef,
      onAddReset,
      handleSearch,
      onSelect,
      onSourceFromChange,
      enableOwnerWarehouseBindInfoData,
    } = this.props.store;
    return (
      <Form
        {...layout}
        ref={addFromRef}
      >
        <Modal
          centered
          confirmLoading={confirmLoading}
          maskClosable={false}
          onCancel={onAddReset}
          onOk={handleOk}
          title="新增网仓货主"
          visible={isModalVisible}
        >
          <Form.Item
            className={css.removeFromMargin}
            initialValue={0}
            label="用户类型"
            name="sourceFrom"
          >
            <Radio.Group onChange={onSourceFromChange}>
              <Radio value={0}>
                供应商
              </Radio>
              <Radio value={1}>
                品牌方
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            className={css.removeFromMargin}
            label="用户名"
            name="ownerName"
            rules={[{ required: true }]}
          >
            <Select
              defaultActiveFirstOption={false}
              filterOption={false}
              notFoundContent={null}
              onSearch={handleSearch.bind(this, 'ownerName')}
              onSelect={onSelect.bind(this, 'ownerCellphone')}
              options={enableOwnerWarehouseBindInfoData.map((item) => ({
                value: item.ownerId,
                label: item.ownerName,
              }))}
              placeholder="请选择用户名"
              showArrow={false}
              showSearch
            />
          </Form.Item>
          <Form.Item
            className={css.removeFromMargin}
            label="手机号"
            name="ownerCellphone"
            rules={[
              {
                required: true,
                message: '请选择手机号码',
              },
            ]}
          >
            <Select
              defaultActiveFirstOption={false}
              filterOption={false}
              notFoundContent={null}
              onSearch={handleSearch.bind(this, 'ownerCellphone')}
              onSelect={onSelect.bind(this, 'ownerName')}
              options={enableOwnerWarehouseBindInfoData.map((item) => ({
                value: item.ownerId,
                label: item.ownerCellphone,
              }))}
              placeholder="请选择手机号码"
              showArrow={false}
              showSearch
            />
          </Form.Item>
          <Form.Item
            className={css.removeFromMargin}
            label="货主名"
            name="ownerUniqueName"
            rules={[
              {
                required: true,
                message: '请输入货主名称/货主名只能是文字、数字、英文字母/货主名不能超过5个字符',
              },
            ]}
          >
            <Input
              autoComplete="off"
              maxLength={5}
              placeholder="货主名不超过5个字符"
            />
          </Form.Item>
          <Form.Item
            className={css.removeFromMargin}
            label="昵称"
            name="nickName"
            rules={[
              {
                required: true,
                message: '请输入昵称',
              },
            ]}
          >
            <Input
              autoComplete="off"
              maxLength={5}
              placeholder="昵称不超过5个字符"
            />
          </Form.Item>
        </Modal>
      </Form>
    );
  }
}

@inject('store')
@observer
class Content extends React.Component<{ store?: Store; }> {
  render() {
    const {
      enable,
      total,
      pageSize,
      page,
      onRadioChange,
      onPaginationChange,
      handlePlusServiceModal,
      checkedItems,
    } = this.props.store;
    return (
      <div className={css.body}>
        <div className={css.bodyHeader}>
          <Space>
            <Button
              disabled={checkedItems.length === 0}
              onClick={() => handlePlusServiceModal(checkedItems.map((item) => item.ownerId), [])}
            >
              <i className="icon-function_setup"/>
              &nbsp;
              批量开通增值服务
            </Button>
          </Space>
          <Radio.Group
            buttonStyle="solid"
            onChange={onRadioChange}
            value={enable}
          >
            <Radio.Button value={2}>
              全部
            </Radio.Button>
            <Radio.Button value={1}>
              启用中
            </Radio.Button>
            <Radio.Button value={0}>
              禁用中
            </Radio.Button>
          </Radio.Group>
        </div>
        <div className={css.card}>
          <div className={css.shipperCard}>
            <ShipperCard/>
          </div>
          <Row
            className={css.pagination}
            justify="end"
          >
            <Col>
              <Pagination
                current={page}
                onChange={onPaginationChange}
                pageSize={pageSize}
                pageSizeOptions={['11']}
                showQuickJumper
                showSizeChanger
                total={total}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

@observer
export default class extends React.Component {
  public store = new Store();

  render() {
    const {
      normalProgramme,
      showEditPlusServiceModal,
      activeItem,
      handleCloseEditPlusServiceModal,
    } = this.store;
    return (
      <Provider store={this.store}>
        <div className={css.page}>
          <Card size="small">
            <NormalProgrammeComponent store={normalProgramme}/>
          </Card>
          <Content/>
        </div>
        <AddModal/>
        {
          showEditPlusServiceModal ? (
            <PlusServiceEditModal
              activeItem={activeItem}
              onCancel={handleCloseEditPlusServiceModal}
            />
          ) : null
        }
      </Provider>
    );
  }
}
