import { Card, Modal, Tabs } from 'antd';
import { MainSubStructure, NormalProgrammeComponent } from 'egenie-utils';
import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import type { OwnerItem } from '../types';
import styles from './plusServiceEditModal.less';
import { ENUM_BUSINESS_TYPE, Store } from './store';

interface PlusServiceEditModalProps {
  activeItem: OwnerItem;
  onCancel: () => void;
}

@observer
export class PlusServiceEditModal extends React.Component<PlusServiceEditModalProps> {
  constructor(props: PlusServiceEditModalProps) {
    super(props);
    this.store = new Store(props.activeItem);
  }

  public store: Store;

  render() {
    const {
      props: { onCancel },
      store,
    } = this;
    return (
      <Provider store={store}>
        <Modal
          centered
          footer={null}
          maskClosable={false}
          onCancel={onCancel}
          title="编辑增值服务"
          visible
          width={1000}
        >
          <div className={styles.plusServiceEditModalContainer}>
            <Pane/>
            <GridAndFilterItems/>
          </div>
        </Modal>
      </Provider>
    );
  }
}

@inject('store')
@observer
class GridAndFilterItems extends React.Component<{ store?: Store; }> {
  render() {
    const { state } = this.props.store;
    return (
      <>
        <Card size="small">
          <NormalProgrammeComponent store={state.normalProgramme}/>
        </Card>
        <div className={styles.tableWrapper}>
          <MainSubStructure store={state.gridModel}/>
        </div>
      </>
    );
  }
}

@inject('store')
@observer
class Pane extends React.Component<{ store?: Store; }> {
  render() {
    const {
      businessType,
      handleBusinessTypeChange,
    } = this.props.store.extraFilterItemsStore;
    return (
      <Tabs
        activeKey={businessType}
        onChange={handleBusinessTypeChange}
      >
        {
          Object.values(ENUM_BUSINESS_TYPE)
            .map((item) => (
              <Tabs.TabPane
                key={item.value}
                tab={item.label}
              />
            ))
        }
      </Tabs>
    );
  }
}
