import { Drawer, Typography } from 'antd';
import type { BaseData } from 'egenie-common';
import { request, RenderByCondition } from 'egenie-common';
import React from 'react';
import { ENUM_PLUS_SERVICE_TYPE } from '../../../utils';
import styles from './plusServiceDetailModal.less';
import type { MaterialItem } from '../../materialManage/types';

interface PlusServiceDetailModalProps {
  onCancel?: (...arg: any[]) => any;
  data?: { ownerId: string | number; businessType: string | number; businessId: string | number; valueAddedTypeList: string[] | number[]; };
}

interface PlusServiceDetailItem {
  valueAddedType?: string;
  valueAddedName?: string;
  valueAddedConfigVO?: {
    baseSkuNo?: string;
    baseSkuId?: string;
    checkNode?: string;
    checkNodeName?: string;
    wmsMaterialShowVO?: MaterialItem;
    templateId?: string;
    templateName?: string;
  };
}

export const PlusServiceDetailModal: React.FC<PlusServiceDetailModalProps> = function({
  onCancel,
  data,
}) {
  const [
    detailData,
    setDetailData,
  ] = React.useState<PlusServiceDetailItem[]>([]);

  React.useEffect(() => {
    request<BaseData<PlusServiceDetailItem[]>>({
      url: '/api/cloud/baseinfo/rest/value/added/detail/material/list',
      method: 'post',
      data,
    })
      .then((info) => setDetailData((info.data || []).map((item) => ({
        ...item,
        valueAddedType: `${item.valueAddedType}`,
      }))));
  }, []);

  return (
    <Drawer
      maskClosable={false}
      onClose={onCancel}
      title="增值服务详情"
      visible
      width={360}
    >
      {
        detailData.map((item) => (
          <div
            className={styles.plusServiceDetailItem}
            key={item.valueAddedType}
          >
            <header>
              <span/>
              {item.valueAddedName}
            </header>
            <RenderByCondition
              show={[
                ENUM_PLUS_SERVICE_TYPE.changeDecorate.value,
                ENUM_PLUS_SERVICE_TYPE.changeTheTag.value,
                ENUM_PLUS_SERVICE_TYPE.praiseCard.value,
                ENUM_PLUS_SERVICE_TYPE.ironPrint.value,
              ].includes(item.valueAddedType)}
            >
              <section className={styles.materialContainer}>
                <div className={styles.left}>
                  <img
                    height={88}
                    src={item?.valueAddedConfigVO?.wmsMaterialShowVO?.pic}
                    width={88}
                  />
                </div>
                <div className={styles.right}>
                  <section>
                    <Typography.Text
                      ellipsis
                      title={item.valueAddedConfigVO?.wmsMaterialShowVO?.materialName}
                    >
                      {item.valueAddedConfigVO?.wmsMaterialShowVO?.materialName}
                    </Typography.Text>
                  </section>
                  <section>
                    <Typography.Text
                      ellipsis
                      title={item.valueAddedConfigVO?.wmsMaterialShowVO?.spec}
                    >
                      尺寸:&nbsp;&nbsp;
                      {item.valueAddedConfigVO?.wmsMaterialShowVO?.spec}
                    </Typography.Text>
                  </section>
                  <section>
                    <Typography.Text
                      ellipsis
                      title={`${item.valueAddedConfigVO?.wmsMaterialShowVO?.weight || ''}`}
                    >
                      重量:&nbsp;&nbsp;
                      {item.valueAddedConfigVO?.wmsMaterialShowVO?.weight}
                      g
                    </Typography.Text>
                  </section>
                  <RenderByCondition show={item.valueAddedType === ENUM_PLUS_SERVICE_TYPE.ironPrint.value}>
                    <section>
                      <Typography.Text
                        ellipsis
                        title={item.valueAddedConfigVO?.baseSkuNo || ''}
                      >
                        白板SKU:&nbsp;&nbsp;
                        {item.valueAddedConfigVO?.baseSkuNo}
                      </Typography.Text>
                    </section>
                  </RenderByCondition>
                </div>
              </section>
            </RenderByCondition>
            <RenderByCondition show={ENUM_PLUS_SERVICE_TYPE.certificate.value === item.valueAddedType}>
              <Typography.Text
                ellipsis
                style={{
                  fontWeight: 500,
                  maxWidth: '100%',
                }}
              >
                {item?.valueAddedConfigVO?.templateName}
              </Typography.Text>
            </RenderByCondition>
            <RenderByCondition show={ENUM_PLUS_SERVICE_TYPE.deliveryList.value === item.valueAddedType}>
              <Typography.Text
                ellipsis
                style={{
                  fontWeight: 500,
                  maxWidth: '100%',
                }}
              >
                {ENUM_PLUS_SERVICE_TYPE.deliveryList.label}
              </Typography.Text>
            </RenderByCondition>
            <RenderByCondition show={ENUM_PLUS_SERVICE_TYPE.qualityTesting.value === item.valueAddedType}>
              <Typography.Text
                ellipsis
                style={{
                  fontWeight: 500,
                  maxWidth: '100%',
                }}
              >
                {item?.valueAddedConfigVO?.checkNodeName}
              </Typography.Text>
            </RenderByCondition>
          </div>
        ))
      }
    </Drawer>
  );
};
