import { Button, message, Tag } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { ImgFormatter, MainSubStructureModel, NormalProgramme, request } from 'egenie-utils';
import _ from 'lodash';
import { Observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import { ENUM_OWNER_MANAGE_PLUS_SERVICE_TYPE, ENUM_PLUS_SERVICE_TYPE, ENUM_QUALITY_TESTING_PLUS_SERVICE_NODE } from '../../../utils';
import type { PlusServiceItem } from '../plusServiceListModal';
import type { SkuItem } from '../types';
import type { Store } from './store';
import { ENUM_BUSINESS_TYPE } from './store';
import styles from './plusServiceEditModal.less';
import { PlusServiceDetailModal } from './plusServiceDetailModal';
import { destroyAllModal, renderModal } from 'egenie-common';

export class SkuStore {
  constructor(parent: Store) {
    this.parent = parent;
    this.gridModel = new MainSubStructureModel({
      grid: {
        columns: [
          {
            key: 'operate',
            name: '操作',
            width: 80,
            frozen: true,
            formatter: ({ row }) => {
              return (
                <Button
                  onClick={() => {
                    const data = Object.values(ENUM_OWNER_MANAGE_PLUS_SERVICE_TYPE)
                      .filter((item) => this.parent.activeItem.addedValueService.includes(item.value))
                      .map((item) => ({
                        ...item,
                        checked: row.addedValueService.includes(item.value),
                      }));
                    const qualityTestingData: PlusServiceItem[] = Object.values(ENUM_QUALITY_TESTING_PLUS_SERVICE_NODE)
                      .map((item) => ({
                        value: item.value,
                        label: item.label,
                        checked: row.checkNode.includes(item.value),
                        renderBy: ENUM_OWNER_MANAGE_PLUS_SERVICE_TYPE.qualityTesting.value,
                      }));
                    this.parent.handleInlinePlusServiceModal([row.skuId], ENUM_BUSINESS_TYPE.sku.value, data.concat(qualityTestingData));
                  }}
                  type="link"
                >
                  增值服务
                </Button>
              );
            },
          },
          {
            key: 'skuId',
            name: '增值服务',
            width: 300,
            formatter: ({ row }) => {
              return (
                <>
                  <span
                    className={styles.plusDetailButton}
                    onClick={() => {
                      renderModal((
                        <PlusServiceDetailModal
                          data={{
                            ownerId: this.parent.activeItem.ownerId,
                            businessType: this.parent.extraFilterItemsStore.businessType,
                            businessId: row.skuId,
                            valueAddedTypeList: row.addedValueService,
                          }}
                          onCancel={destroyAllModal}
                        />
                      ));
                    }}
                  >
                    详情
                  </span>
                  &nbsp;
                  <Observer>
                    {
                      () => row.addedValueService.map((val) => (
                        <Tag
                          color="blue"
                          key={val}
                        >
                          {Object.values(ENUM_PLUS_SERVICE_TYPE).find((labelData) => _.toString(labelData.value) === _.toString(val))?.label}
                        </Tag>
                      ))
                    }
                  </Observer>
                </>

              );
            },
          },
          {
            key: 'skuNo',
            name: 'SKU编码',
            width: 200,
          },
          {
            key: 'productNo',
            name: '商品编码',
            width: 200,
          },
          {
            key: 'barCode',
            name: '条形码',
            width: 200,
          },
          {
            key: 'picUrl',
            name: '图片',
            width: 50,
            formatter: ({ row }) => <ImgFormatter value={row.picUrl}/>,
          },
          {
            key: 'color',
            name: '颜色',
            width: 80,
          },
          {
            key: 'size',
            name: '尺码',
            width: 80,
          },
          {
            key: 'sellerOuterNo',
            name: '货号',
            width: 200,
          },
          {
            key: 'vendorName',
            name: '供应商名称',
            width: 200,
          },
        ].map((info) => ({
          resizable: true,
          sortable: false,
          ...info,
        })),
        rows: [],
        primaryKeyField: '_id',
        sortByLocal: false,
        showCheckBox: true,
        showEmpty: true,
        pageSize: 50,
      },
      buttons: [
        this.parent.activeItem.addedValueService.includes(ENUM_OWNER_MANAGE_PLUS_SERVICE_TYPE.qualityTesting.value) && {
          icon: 'icon-function_setup',
          text: ENUM_OWNER_MANAGE_PLUS_SERVICE_TYPE.qualityTesting.label,
          handleClick: () => {
            if (this.gridModel.gridModel.selectRows.length === 0) {
              message.warning({
                key: '请至少选择一条数据',
                content: '请至少选择一条数据',
              });
              return;
            }

            this.parent.handleBatchPlusServiceModal(this.gridModel.gridModel.selectRows.map((item) => item.skuId), ENUM_BUSINESS_TYPE.sku.value, [ENUM_OWNER_MANAGE_PLUS_SERVICE_TYPE.qualityTesting.value]);
          },
        },
        this.parent.activeItem.addedValueService.includes(ENUM_OWNER_MANAGE_PLUS_SERVICE_TYPE.deliveryList.value) && {
          icon: 'icon-function_setup',
          text: ENUM_OWNER_MANAGE_PLUS_SERVICE_TYPE.deliveryList.label,
          handleClick: () => {
            if (this.gridModel.gridModel.selectRows.length === 0) {
              message.warning({
                key: '请至少选择一条数据',
                content: '请至少选择一条数据',
              });
              return;
            }

            this.parent.handleBatchPlusServiceModal(this.gridModel.gridModel.selectRows.map((item) => item.skuId), ENUM_BUSINESS_TYPE.sku.value, [ENUM_OWNER_MANAGE_PLUS_SERVICE_TYPE.deliveryList.value]);
          },
        },
      ].filter(Boolean),
      api: {
        onQuery: ({
          filterParams = {},
          ...rest
        } = {}) => {
          return request<PaginationData<SkuItem>>({
            url: '/api/cloud/baseinfo/rest/value/added/query/sku',
            method: 'POST',
            data: {
              ...filterParams,
              ...rest,
            },
          })
            .then((info) => ({
              ...info,
              data: {
                ...info.data,
                list: (info?.data?.list || []).map((item) => ({
                  ...item,
                  _id: nanoid(),
                  addedValueService: (item.addedValueService || []).map((val) => `${val}`),
                  checkNode: (item.checkNode || []).map((val) => `${val}`),
                })),
              },
            }));
        },
      },
      hiddenSubTable: true,
    });
    this.normalProgramme = new NormalProgramme({
      count: 4,
      filterItems: [
        {
          type: 'input',
          field: 'skuNo',
          label: 'SKU编码',
        },
        {
          type: 'input',
          field: 'productNo',
          label: '商品编码',
        },
        {
          type: 'select',
          field: 'vendorIdList',
          label: '供应商名称',
        },
        {
          type: 'select',
          field: 'addedValueService',
          label: '增值服务',
          mode: 'multiple',
          isParamList: true,
          data: Object.values(ENUM_PLUS_SERVICE_TYPE),
        },
      ],
      handleSearch: () => this.gridModel.onQuery(),
    });
  }

  public parent: Store;

  public gridModel: MainSubStructureModel;

  public normalProgramme: NormalProgramme;
}
